export default function SvgFilterSort() {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.1 3.7L4.5 1M4.5 1L1 3.7M4.5 1L4.5 15"
        stroke="#514B4D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.9 12.3L13.5 15M13.5 15L17 12.3M13.5 15V1"
        stroke="#514B4D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
