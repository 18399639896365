export default function SvgArrowDown() {
  return (
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 4.2L5.5 1M5.5 1L1.5 4.2M5.5 1L5.5 17"
        stroke="#514B4D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
